<template>
  <div class="loginAndRegister document-content">登录注册</div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.loginAndRegister {
}
</style>